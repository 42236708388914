import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useAuth } from 'react-oidc-context';
import { getEnv } from '../utils/environment';
import defaultPermissions from './permissions.default.json';

export const Roles = createContext({});

const env = getEnv();
const subPath = env === 'prod' ? '' : env;
const apiUrl = `https://sfe.dsmotd${subPath}.toyota.com/otdgetadminpermission/get-admin-permissions`; // permissions api url

const RolesProvider = ({ children }) => {
  const auth = useAuth();

  const [permissions, setPermissions] = useState(defaultPermissions);

  useEffect(() => {
    const token = auth?.user?.access_token;
    if (!token) {
      return;
    }

    const body = { access_token: token };
    const headers = { Authorization: token };

    axios
      .post(apiUrl, body, { headers })
      .then((response) => {
        setPermissions(response?.data || defaultPermissions);
      })
      .catch((e) => e);
  }, [auth?.user?.access_token]);

  return <Roles.Provider value={{ permissions }}>{children}</Roles.Provider>;
};

RolesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RolesProvider;
