import React from 'react';
import PropTypes from 'prop-types';
import * as S from './LoadingSpinner.styles';

const LoadingSpinner = ({ fullScreen }) => {
  return (
    <S.LoaderContainer data-testid="loading-spinner" fullScreen={fullScreen}>
      <div className="loader"></div>
    </S.LoaderContainer>
  );
};

LoadingSpinner.propTypes = {
  fullScreen: PropTypes.bool,
};

LoadingSpinner.defaultProps = {
  fullScreen: false,
};

export default LoadingSpinner;
