import React, { createContext, useReducer, useContext } from 'react';
import PropTypes from 'prop-types';

const AccordionContext = createContext();

const accordionReducer = (state, action) => ({ ...state, [action.id]: !state[action.id] });

export const AccordionProvider = ({ children }) => {
  const [state, dispatch] = useReducer(accordionReducer, {});

  return (
    <AccordionContext.Provider value={{ state, dispatch }}>{children}</AccordionContext.Provider>
  );
};

AccordionProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export const useAccordion = () => useContext(AccordionContext);
