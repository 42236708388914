import React from 'react';
import { createRoot } from 'react-dom/client';
import { AuthProvider } from 'react-oidc-context';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Router from './Router';
import theme from './theme';
import NotificationProvider from 'context/Notification.context';
import { AccordionProvider } from 'context/AccordionContext';
import RolesProvider from 'context/Roles.context';
import VSpecProvider from 'context/Vspec.context';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

const forgerockUri = window.location.origin.includes('dsmotd.toyota.com')
  ? 'https://ep.fram.idm.toyota.com/openam/oauth2/realms/root/realms/dealerdaily'
  : 'https://ep.fram.qa.idm.toyota.com/openam/oauth2/realms/root/realms/dealerdaily';

const oidcConfig = {
  onSigninCallback: () => {
    const pathname = sessionStorage.getItem('cube-pathname') || '/';
    sessionStorage.removeItem('cube-pathname');
    window.history.replaceState(null, '', window.location.origin + pathname);
  },
  authority: forgerockUri,
  client_id: 'DSMOTD',
  redirect_uri: window.location.origin,
  scope: 'openid profile',
  post_logout_redirect_uri: window.location.origin,
};

const queryClient = new QueryClient();

root.render(
  <AuthProvider {...oidcConfig}>
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <CssBaseline />
        <RolesProvider>
          <VSpecProvider>
            <AccordionProvider>
              <NotificationProvider>
                <Router />
              </NotificationProvider>
            </AccordionProvider>
          </VSpecProvider>
        </RolesProvider>
      </QueryClientProvider>
    </ThemeProvider>
  </AuthProvider>,
);
